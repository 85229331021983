<template>

      <dealers/>

</template>

<script>
import Dealers from "@/components/dealers";

export default {
  name: 'App',

  components: {
    Dealers,
  },

  data: () => ({
    //
  }),
};
</script>
